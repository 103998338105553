import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TechIcon (props) {
  const techName = props.icon
  const icon = techName.toLowerCase().replaceAll(" ", "")
  let iconType = 'plain'

  if (icon === 'express' || icon === 'electron' || icon === 'ionic') {
    iconType = 'original'
  }

  if (icon === 'less') {
    iconType = 'plain-wordmark'
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {techName}
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement="left"
        overlay={renderTooltip}
      >
        <div className='d-inline-block'>
          <i className={`devicon-${icon}-${iconType} stack-icon text-black-50`}></i>
        </div>
      </OverlayTrigger>
    </>
  )
}

export default TechIcon