import React, { useState } from 'react';

import './App.scss';

import Hero from './components/Hero'
import Portfolio from './components/Portfolio'
import Skills from './components/Skills';
import Lightbox from './components/Lightbox';

function App() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Lightbox show={show} setShow={setShow}></Lightbox>
      <Hero></Hero>
      <Portfolio setShow={setShow}></Portfolio>
      <Skills></Skills>
    </>
  );
}

export default App;
