import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Hero() {
  return (
      <section>
        <Container className="my-md-5 py-5 px-4 px-md-2">
          <Row>
            <Col xs={6} md={2}>
              <img src='/assets/img/flynn.jpg' alt='Flynn' className='rounded-circle w-100'></img>
            </Col>
          </Row>
          <Row className='pt-5 pb-3'>
            <Col xs={12} md={6}>
              <h2 className='fw-bold'>Hi, I'm Flynn Tesoriero</h2>
              <h5 className='text-muted'>Front End Engineer</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <p className="text-muted">I'm a product-oriented Front End Engineer from Sydney, Australia. I like to design and build beautiful digital products that people love. I'm an avid mountain bike rider & rock climber, too.<br /> Currently, I'm a Software Engineer at Shippit, a multicarrier logistics platform. I also contribute to Open Source projects.</p>
            </Col>
          </Row>
        </Container>
      </section>
  )
}

export default Hero