import React, { useState, useEffect } from 'react'
import { XLg } from 'react-bootstrap-icons'

import Modal from 'react-bootstrap/Modal';

function Lightbox({show, setShow}) {
  const handleClose = () => setShow(false);
  const [imgURL, setImgURL] = useState("");

  // Update image URL if we have a valid (non-false) URL
  useEffect(() => {
    if (show) {
      setImgURL(show)
    }
  }, [show])

  return (
    <Modal show={show} onHide={handleClose} size='xl' centered={true} contentClassName="border-0 shadow align-items-flex-start">
      <div onClick={handleClose} className='modal-close'>
        <XLg width={20} height={20}></XLg>
      </div>
      <img className='rounded w-100' src={imgURL} alt="portfolio item"></img>
    </Modal>
  )
}

export default Lightbox