import React from "react"
import Carousel from 'react-bootstrap/Carousel';


function Slider({ slides, portfolioItem, setShow }) {
  const handleImageClick = (img => {
    setShow(img);
  })

  return (
    <>
      <Carousel variant="dark" className="rounded shadow">
        {slides.map((data, key) => {
          return (
            <Carousel.Item key={key} className='rounded'>
              <img onClick={() => handleImageClick(data)} className="d-block w-100 rounded" src={data} alt={`${portfolioItem.name} portfolio slide ${key+1}`} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  )
}

export default Slider