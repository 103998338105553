import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PortfolioItem from './PortfolioItem';

import { portfolioItems } from "../data/portfolioItems";

function Portfolio({ setShow }) {
  return (
    <section className='bg-white py-md-5 px-2 px-md-2'>
      <Container className='pt-5'>
        <Row>
          <Col>
            <h1 className="fw-bold">Work</h1>
          </Col>
        </Row>
      </Container>
      
      {portfolioItems.map((data, key) => {
        return (
          <PortfolioItem key={key} portfolioID={key} item={data} setShow={setShow}></PortfolioItem>
        );
      })}
    </section>
  )
}

export default Portfolio