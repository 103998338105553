import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { skillItems } from "../data/skillItems";

function Skills() {
  return (
    <>
      <section className='bg-white py-4 py-md-5 px-2'>
        <Container className='py-md-5'>
          <Row>
            <Col md={4}>
              <h1 className="fw-bold">Skills</h1>
            </Col>
            <Col md={8}>
              <ul>
                {skillItems.map((data, key) => {
                  return (
                    <li key={key}>{data}</li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Skills