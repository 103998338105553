export const portfolioItems = [
  {
    name: "Mobimag",
    time: "2020 - 2022",
    description: "Mobimag is a mobile magazine publishing platform that allows designers to publish beautiful mobile-first publications using only PDF files. I led front end and infrastructure development, from selecting the tech stack to writing code and prioritising features. The application was built in Angular and powered by a TypeScript and NodeJS backend.",
    link: 'https://mobimag.co',
    images: [
      '/assets/img/portfolio/mobimag/1.png',
      '/assets/img/portfolio/mobimag/2.png',
      '/assets/img/portfolio/mobimag/3.png',
      '/assets/img/portfolio/mobimag/4.png',
    ],
    tech: [
      'Angular JS',
      'Express',
      'TypeScript',
      'Sass',
      'NodeJS',
      'Gulp',
      'Webpack',
      'Redis',
      'Firebase',
      'Docker',
      'Kubernetes',
      'Google Cloud',
    ]
  },
  {
    name: "Sunrise Daily",
    time: "2020 - Present",
    description: "Sunrise Daily offers the largest and highest quality collection of daily sunrise photos, available for purchase on print or canvas. I built the front end and backend of the site, from UX design to photo processing and order management. The site is built atop WooCommerce and AWS.",
    link: 'https://sunrisedaily.co',
    images: [
      '/assets/img/portfolio/sunrise-daily/1.png',
      '/assets/img/portfolio/sunrise-daily/2.png',
      '/assets/img/portfolio/sunrise-daily/3.png',
      '/assets/img/portfolio/sunrise-daily/4.png',
      '/assets/img/portfolio/sunrise-daily/5.png',
    ],
    tech: [
      'PHP',
      'Sass',
      'jQuery',
      'Gulp',
      'Apache',
      'Amazon Web Services',
    ]
  },
  // {
  //   name: "Bike Database Network",
  //   time: "2018 - Present",
  //   description: "Bike Database Network is a collection of product comparison sites focused on cycling. The sites are collectively used by over 200,000 people each month to find and compare bikes. I designed and built the front end of the sites and manage growth & optimisation.",
  //   link: 'https://bikedatabases.com',
  //   images: [
  //     '/assets/img/portfolio/bike-database/1.png',
  //     '/assets/img/portfolio/bike-database/2.png',
  //     '/assets/img/portfolio/bike-database/3.png',
  //     '/assets/img/portfolio/bike-database/4.png',
  //     '/assets/img/portfolio/bike-database/5.png',
  //   ],
  //   tech: [
  //     'TypeScript',
  //     'PHP',
  //     'Sass',
  //     'Gulp',
  //     'MongoDB',
  //     'Nginx',
  //     'NodeJS',
  //     'Redis',
  //     'Selenium',
  //   ]
  // },
  {
    name: "Studio Dashboard",
    time: "2018",
    description: "Built on Electron and written in JavaScript, Studio Dashboard is a cross-platform app bringing together a number of separate video production systems into a single package. The desktop app allows for camera switching, lighting control, marker recording and project generation.",
    images: [
      '/assets/img/portfolio/studio-dashboard/1.png',
      '/assets/img/portfolio/studio-dashboard/2.png',
      '/assets/img/portfolio/studio-dashboard/3.png',
      '/assets/img/portfolio/studio-dashboard/4.png',
    ],
    tech: [
      'Electron',
      'JavaScript',
      'Firebase',
      'Google Cloud',
    ]
  },
  {
    name: "Miles",
    time: "2018",
    description: "Miles was an app built in Ionic allowing users to keep track of everything they're working towards while also motivating them to reach their goals.",
    images: [
      '/assets/img/portfolio/miles/1.png',
    ],
    tech: [
      'Ionic',
      'React',
      'TypeScript',
      'Sass',
      'Firebase',
    ]
  },
  {
    name: "Business Asset Manager",
    time: "2017",
    description: "Business Asset Manager (BAM) is an asset tracking system built atop WordPress. It allows for simple asset creation, management and reporting on desktop or mobile. Search is integrated through Algolia and the application runs on AWS.",
    images: [
      '/assets/img/portfolio/bam/1.png',
      '/assets/img/portfolio/bam/2.png',
      '/assets/img/portfolio/bam/3.png',
    ],
    tech: [
      'PHP',
      'JavaScript',
      'Sass',
      'Gulp',
      'Apache',
    ]
  },
  {
    name: "Ask Business Blueprint",
    time: "2017",
    description: "Ask Business Blueprint is a real-time web app built in Angular and Firebase that facilitates audience interaction at live events. Users can ask questions of presenters and chat in real-time with other guests through any mobile or desktop device.",
    images: [
      '/assets/img/portfolio/askbb/1.png',
      '/assets/img/portfolio/askbb/2.png',
      '/assets/img/portfolio/askbb/3.png',
    ],
    tech: [
      'Angular JS',
      'JavaScript',
      'Sass',
      'Firebase',
    ]
  },
  {
    name: "Sleeky",
    time: "2015 - Present",
    description: "Sleeky is the complete solution for adding a beautiful front end and backend to the YOURLS (Your Own URL Shortener) software. Sleeky adds a customisable front end and restyles the backend of YOURLS. I started and manage the open source project, which has now grownt to over 350 stars on GitHub.",
    link: 'https://sleeky.flynntes.com',
    images: [
      '/assets/img/portfolio/sleeky/1.png',
      '/assets/img/portfolio/sleeky/2.png',
      '/assets/img/portfolio/sleeky/3.png',
      '/assets/img/portfolio/sleeky/4.png',
      '/assets/img/portfolio/sleeky/5.png',
    ],
    tech: [
      'PHP',
      'JavaScript',
      'LESS',
    ]
  },
]