export const skillItems = [
  'Strong web fundamentals, with 5+ years of experience designing, building and deploying beautiful, scalable web applications',
  'Proficiency in TypeScript, JavaScript, CSS and HTML',
  'Experience using JavaScript frameworks (React, Angular), and vanilla JavaScript',
  'Familiarity with pre-processors, task runners, and bundlers (Sass, Grunt, gulp, Browserify, webpack, npm, Yarn)',
  'Strong proficiency with server-side languages, frameworks and integrating APIs (Java, Python, PHP, Node.js)',
  'Ability to set up Continuous Integration, Continuous Delivery, and virtualization environments (Azure Pipelines, GitHub Actions, AWS Code Pipeline, Travis CI, Docker, Kubernetes)',
  'Experienced in building and scaling cloud environments, across AWS, Google Cloud, Azure and IBM Cloud',
  'Advanced level knowledge of Git version control system, GitHub, GitLab and the command line',
  'Deep understanding of DevTools and Lighthouse',
  'Proficiency in building accessible and SEO-optimised applications',
  'Great communication, leadership and collaboration skills',
  'Skilled in gathering requirements and presenting solutions to all stakeholders',
  'Experience writing and communicating technical and product documentation to a range of audiences',
  'Keen eye for design, user experience and prototyping (Adobe Suite, Figma)',
]